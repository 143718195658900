<template>
  <div>
    <label for="language">Choose language:
      <select
        class="p-2 outline-none"
        v-model="lang"
        @change="getData(lang)"
        name="language"
      >
        <option value="en">English</option>
        <option value="ru">Russian</option>
        <option value="az">Azerbaijani</option>
      </select>
    </label>
    <div class="p-4">
      <Table
        :lang="lang"
        :columnNames="['fullName', 'position', 'image', 'story', 'logo']"
        :keys="['fullName', 'position', 'image', 'logo', 'story']"
        @getData="getData(lang)"
        :requests="requests"
      />
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import requests from '../services/requests/CustomerStoriesRequests.js'

export default {
  data () {
    return {
      lang: 'en',
      requests: requests
    }
  },
  name: 'CustomerStories',
  components: {
    Table
  },
  methods: {
    // gets data from backend
    async getData (lang) {
      this.$emit('loading', true)
      const temp = await this.requests.getAll(lang)
      this.$store.commit('setData', temp)
      this.$emit('loading', false)
    }
  },
  created () {
    // fills table with data from backend on created
    this.getData(this.lang)
  }
}
</script>
